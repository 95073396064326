<template>
    <div class="frame">
      <div class="container">
        <h1 class="title">Remote ID 상세보기</h1>
        <div class="info-box">
          <div class="info-center">
            <div class="left">
              <div class="input-box">
                <p>생성시간</p>
                <input type="text" v-model="droneDeviceInfo.createdAt" disabled>
              </div>
              <div class="input-box">
                <p>디바이스명<strong>*</strong></p>
                <input type="text" placeholder="디바이스명을 입력해주세요" v-model="droneDeviceInfo.deviceId" :disabled="isUpdated">
              </div>
              <div class="input-box">
                <p>형식</p>
                <select v-model="selectedType" id="type">
                    <option value="" disabled selected>형식을 선택해주세요</option>
                    <option v-for="item in droneType" :key="item.name" :value="item.name">{{ item.name }}</option>
                </select>
              </div>
              <div class="input-box">
                <p>최종인증검사일</p>
                <input type="text" placeholder="최종인증검사일을 입력해주세요 ex)22.12.22" v-model="droneDeviceInfo.date">
              </div>
              <div class="input-box">
                <p>최대이륙중량</p>
                <input type="text" placeholder="최대이륙중량을 입력해주세요 ex)42" v-model="droneDeviceInfo.mtow">
              </div>
              <div class="input-box">
                <p>디스플레이여부</p>                        
                <input type="checkbox" v-model="ischecked">                              
              </div>

            </div>
            <div class="right">
              <div class="input-box">
                <p>수정시간</p>
                <input type="text" v-model="droneDeviceInfo.updatedAt" disabled>
              </div>
              <div class="input-box">
                <p>종류</p>
                <input type="text" placeholder="종류를 입력해주세요 ex)무인멀티콥터" v-model="droneDeviceInfo.category">
              </div>
              <div class="input-box">
                <p>신고번호</p>
                <input type="text" placeholder="신고번호를 입력해주세요 ex)S7733I" v-model="droneDeviceInfo.no">
              </div>
              <div class="input-box">
                <p>자체중량</p>
                <input type="text" placeholder="자체중량을 입력해주세요 ex)24.5" v-model="droneDeviceInfo.weight">
              </div>
              <div class="input-box">
                <p>인증기관</p>
                <input type="text" placeholder="인증기관을 입력해주세요 ex)아쎄따" v-model="droneDeviceInfo.organization">
              </div>
              <div class="input-box">
                <p>비고</p>
                <input type="text" placeholder="비고를 입력해주세요" v-model="droneDeviceInfo.note">
              </div>

              
            </div>
          </div>
          <div class="line"/>
          <div class="info-footer">
            <button class="custom-btn-box btn-back" v-if="showBackButton" @click="backButton">목록</button>
            <button class="custom-btn-box btn-delete" v-if="showDeleteButton" @click="showAlert">삭제</button>
            <button class="custom-btn-box btn-edit" v-if="showEditButton" @click="editButton">수정</button>
            <button class="custom-btn-box btn-add" v-if="showAddButton" @click="addButton">추가</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "@/axios";
  
  export default {
    name: "DroneDeviceInfo",
    data() {
      return {
        token: this.$store.state.token.access_token,
        id: this.$route.params.id,
        droneDeviceInfo: {
          createdAt: '',
          updatedAt: '',
          deviceId: '',         
          category: '',
          type: '',
          no: '',
          date: '',
          weight: '',
          mtow: '',
          organization: '',          
          uuid: '',
          userId: '',
          name: '',
          isVisible: true,
          note: ''
        },
        droneType: [],
        selectedType: '',
        ischecked: true,
        source: this.$route.params.source,
        showBackButton: true,
        showEditButton: true,
        showDeleteButton: true,
        showAddButton: true,
        isUpdated: true,
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.getDroneType()

        //리스트에서 클릭했을 때만
        if (this.source === "update") {
            this.getDroneDeviceInfo()
            this.showBackButton = true
            this.showEditButton = true
            this.showDeleteButton = true
            this.showAddButton = false
            this.isUpdated = true
        }

        if (this.source === "add") {
            this.showBackButton = true
            this.showEditButton = false
            this.showDeleteButton = false
            this.showAddButton = true
            this.isUpdated = false
            this.droneDeviceInfo.category = '무인멀티콥터'
        }

      },
      getDroneDeviceInfo() {
        const self = this;
  
        const config = {
          method: 'get',
          url: `/drones/info/${self.id}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`
          },
        };
  
        axios(config)
            .then((res) => {                
                self.droneDeviceInfo = res.data
                if (self.droneDeviceInfo.type) {
                    self.selectedType = self.droneDeviceInfo.type
                }                
                self.ischecked = self.droneDeviceInfo.isVisible              
            })
      },
      getDroneType() {
        const self = this;
  
        const config = {
          method: 'get',
          url: `/drones/type`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`
          },
        };
  
        axios(config)
            .then((res) => {                
              self.droneType = res.data;
            })
      },
      editButton() {
        const self = this;
  
        if (self.droneDeviceInfo.deviceId.trim().length === 0) {
          self.$swal.fire({title: '입력 필수', text: '디바이스명은 필수 입니다.', icon: 'info'})
          return
        }

        var weight = 0;
        var mtow = 0;
        if (self.droneDeviceInfo.weight !== '') {
            weight = self.droneDeviceInfo.weight
        }
        if (self.droneDeviceInfo.mtow !== '') {
            mtow = self.droneDeviceInfo.mtow
        }

        const data = JSON.stringify({
          "deviceId": self.droneDeviceInfo.deviceId,
          "category": self.droneDeviceInfo.category,
          "type": self.selectedType,
          "no": self.droneDeviceInfo.no,
          "date": self.droneDeviceInfo.date,
          "weight": weight,          
          "mtow": mtow,
          "organization": self.droneDeviceInfo.organization,
          "isVisible": self.ischecked,
          "note": self.droneDeviceInfo.note
        });

        const config = {
            method: 'put',
            url: `/drones/update/${self.id}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${self.token}`
            },
            data: data
        };

        axios(config)
            .then((res) => {
                console.log(res)
                self.$swal
                    .fire({title: '수정 완료', text: '드론 정보가 수정 되었습니다.', icon: 'success'})
                    .then(() => {
                        self.$router.replace({name: 'droneDeviceList'})
                    })
            })
      },

      addButton() {
        const self = this;
  
        if (self.droneDeviceInfo.deviceId.trim().length === 0) {
          self.$swal.fire({title: '입력 필수', text: '디바이스명은 필수 입니다.', icon: 'info'})
          return
        }        
  
        var weight = 0;
        var mtow = 0;
        if (self.droneDeviceInfo.weight !== '') {
            weight = self.droneDeviceInfo.weight
        }
        if (self.droneDeviceInfo.mtow !== '') {
            mtow = self.droneDeviceInfo.mtow
        }
  
        const data = JSON.stringify({
          "deviceId": self.droneDeviceInfo.deviceId,
          "category": self.droneDeviceInfo.category,
          "type": self.selectedType,
          "no": self.droneDeviceInfo.no,
          "date": self.droneDeviceInfo.date,
          "weight": weight,          
          "mtow": mtow,
          "organization": self.droneDeviceInfo.organization,
          "isVisible": self.ischecked,
          "note": self.droneDeviceInfo.note
        });


        const config = {
            method: 'post',
            url: `/drones/create`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${self.token}`
            },
            data: data
        };

        axios(config)
            .then((res) => {
            console.log(res)
            self.$swal
                .fire({title: '추가 완료', text: '드론이 추가 되었습니다.', icon: 'success'})
                .then(() => {
                    self.$router.replace({name: 'droneDeviceList'})
                })
            })       
      },
  
      showAlert() {
        const self = this;
        self.$swal.fire({
          title: '삭제하시겠습니까?',
          text: "삭제 시, 복구가 불가능합니다",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '삭제',
          cancelButtonText: '취소'
        }).then((result) => {
          if (result.isConfirmed) {
            self.deleteButton()
          }
        })
      },
  
      deleteButton() {
        const self = this;
  
        const config = {
          method: 'delete',
          url: `/drones/${self.id}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`
          },
        };
  
        axios(config)
            .then((res) => {
              console.log(res)
              self.$swal
                  .fire({title: '삭제 완료', text: '드론이 삭제 되었습니다.', icon: 'success'})
                  .then(() => {
                    self.$router.replace({name: 'droneDeviceList'})
                  })
            })
      },      
      backButton() {
        this.$router.replace({name: 'droneDeviceList'})
      }
    }
  }
  </script>
  
  <style scoped>
  
  .frame {
    padding: 40px;
  }
  
  .container {
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  
  .title {    
    font-family: 'NotoSansKR', serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    margin-bottom: 20px;
  }
  
  .info-box {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: #FFFFFF;    
    font-family: 'NotoSansKR', serif;
    font-size: 14px;
  }
  
  /* header */
  
  .line {
    background: #E6E7E9;
    height: 1px;
  }
  
  /* center */
  .info-center {
    display: flex;
  }
  
  .left, .right {
    padding: 40px;
    width: 527px;
  }
  
  .left .input-box p {
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    margin-bottom: 10px;
  }
  
  .right .input-box p {
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    margin-bottom: 10px;
  }
  
  .left .input-box strong {
    color: #BE123C;
  }
  
  .right .input-box strong {
    color: #BE123C;
  }
  
  input, select {
    height: 40px;
    width: 100%;
    margin-bottom: 20px;
    padding: 10px 16px 10px;
    outline: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
  }
  
  /* footer */
  .info-footer {
    background: #FFFFFF;
    border-radius: 0 0 8px 8px;
    height: 73px;
    padding: 17px 16px 16px;
    margin-left: auto;
  }

  .info-footer .btn-back {
    background-color: #ff9800; /* 버튼의 배경색 */
    color: white;
    margin: 0 15px 0 0;
    padding: 11px 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    border: 0;
    width: 67px;
}
  
  .info-footer .btn-edit {
    margin: 0;
    padding: 11px 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    border: 0;
    width: 67px;
  }
  
  .info-footer .btn-delete {
    background-color: #ff4b4b;
    color: white;
    margin: 0 15px 0 0;
    padding: 11px 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    border: 0;
    width: 67px;
  }

  .info-footer .btn-add {
  background-color: #4caf50;
  color: white;
  margin: 0 15px 0 0;
  padding: 11px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  border: 0;
  width: 67px;
}

[type=checkbox]:checked, 
[type=checkbox]:not(:checked) {
    position: static;
    pointer-events: auto;
    opacity: 1;
    width: 30px;
}
</style>